import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizQuestion1', 'quizQuestion2', 'quizQuestion3', 'quizQuestion4', 'quizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quiz"
    }}>{`Quiz`}</h1>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion1" once={true} question="QUESTION 01: Anxiety is a normal part of life and it can help us avoid dangerous situations and perform at our best." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Anxiety is a normal part of life and when managed well, can be quite useful
        for protecting us from dangerous situations and helping us perform at our best. See{' '}
        <GoTo to="/m9-anxiety/02-anxiety" mdxType="GoTo">Anxiety and your teenager</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion2" once={true} question="QUESTION 02: If my teenager avoids things that cause them anxiety, their anxiety is likely to reduce." fallbackResponse={<p>
        The correct answer is: <b>False</b>. In the short term, it can feel easier to avoid situations that make you
        anxious. But in the long run, avoiding these situations will only make them harder to face. Gently encourage
        your teenager to face their fears, by doing things that make them anxious, while using helpful strategies to
        manage their anxiety. See{' '}
        <GoTo to="/m9-anxiety/06-facing-fears" mdxType="GoTo">Ways to help your teenager manage anxiety</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion3" once={true} question="QUESTION 03: I should reward and praise my teenager when they are taking steps towards managing their anxiety and facing their fears." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Facing fears isn’t an easy thing to do. Reward, praise and tell your
        teenager that you are proud of them! See{' '}
        <GoTo to="/m9-anxiety/07-other-tips" mdxType="GoTo">Other ways to help your teenager manage anxiety</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion4" once={true} question="QUESTION 04: To help my teenager overcome their worries, I should tell them to “stop being silly” or “don’t be so childish” when they become anxious." fallbackResponse={<p>
        The correct answer is: <b>False</b>. It is important not to make fun of your teenager or minimise their fears.
        Remember, it can feel very overwhelming to your teen to face their fears. See{' '}
        <GoTo to="/m9-anxiety/07-other-tips" mdxType="GoTo">Other ways to help your teenager manage anxiety</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion5" once={true} question="QUESTION 05: I should try to separate my own anxieties and worries from my teenager’s." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Try to remain calm and relaxed when you are talking with your teenager about
        their anxiety. Keep your own anxieties separate from your teenager’s. See{' '}
        <GoTo to="/m9-anxiety/08-manage-own-anxiety" mdxType="GoTo">Help your teenager by managing your own anxiety</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage gridSize={4} smallGridSize={8} src="/images/shared/51.1-01.svg" alt="thinking woman" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      